import React, { Fragment, useContext, useEffect, useState, useCallback } from 'react';
import ProcessContext from '../../../contexts/process/ProcessContext';
import { Modal, ModalBody } from 'reactstrap'
import Panel from '../discarding/panel/Panel';
import Channel from '../discarding/panel/Channel';
import PlantBlockPhotographing from './PlantBlockPhotographing';
import Button from '../../layout/Button';
import {PictureUpload} from '../reporting/PictureUpload';
import StatusBarButton from '../../layout/StatusBarButton';
import PhotoZoomModal from './PhotoZoomModal';
import UIContext from '../../../contexts/ui/UIContext';
import Loading from '../../ui-elements/Loading';


const SubmitPhoto = ({showWithPanelView = true}) => {
  const {
    activeProcessId,
    updateOccupancy,
    updateProcessState,
    modifyProcess,
    continueProcess,
    controlProcess,
    additionalProcessInformation,
    loadOccupancy,
    notifySuccess,
    requestedInformationType,
    occupancyArray,
  } = useContext(ProcessContext);
  const {addNotification, loading, setLoading} = useContext(UIContext);
  const load = async () => {
    await loadOccupancy(additionalProcessInformation.sourceEntity, 'panel');
  };
  const [showUpload, setShowUpload] = useState(false);
  const [photoData, setPhotoData] = useState(null);

  useEffect(() => {
    if(showWithPanelView) {
      load();
    }
    return () => {
      updateOccupancy([]);
    };
  }, []);  

  const receivePhotoData = useCallback((parentData) => {
    if (!parentData?.photoData) {
      setPhotoData(null);
      
    } else {
      setPhotoData(parentData.photoData);
    }
  });

  const submitReport = async () => {
      try {
          setLoading(true);
          let reportData = {
              photoData,
              type: 'PlantPicture',
          };
          await continueProcess(activeProcessId, reportData);
          clearPhoto();

      } catch (error) {
          console.error(error);
      } finally {
          setLoading(false);
      }
  };

  const clearPhoto = () => {    
    setPhotoData(null);
  };  

  const renderBlock = (block, channelNr, index) => {
    const toBePhotographed =
      block.startPlantSlotOrdinalNumber == additionalProcessInformation;

    const isInSelection = additionalProcessInformation?.selectedSlots?.includes(block.startPlantSlotOrdinalNumber);
    const isFinished =
      additionalProcessInformation.startSlotOrdinalNumber > block.endPlantSlotOrdinalNumber && isInSelection;

    const isSelected = additionalProcessInformation.startSlotOrdinalNumber === block.startPlantSlotOrdinalNumber;

    return (
      <PlantBlockPhotographing
        key={`${channelNr}-${block.startPlantSlotOrdinalNumber}`}
        index={index}
        block={block}
        channelNr={channelNr}
        isSelected={isSelected}
        isInSelection={isInSelection}
        isFinished={isFinished}
        style={{height: (block.endPlantSlotOrdinalNumber - block.startPlantSlotOrdinalNumber) + 1 + '%' }}
      />
    );
  };

const [isModalOpen, setIsModalOpen] = useState(false);

  const onPhotoClick = () => {
// Modal muss geöffnet werden
// Close-Button nicht vergessen :)
setIsModalOpen(true);
    }

  return (
    <Fragment>
          {loading ? (
              <Loading fullScreen />
          ) : (
              <>
                  {showWithPanelView && <Panel>
                      {occupancyArray.map((channel) => (
                          <Channel key={channel.channelNumber} channel={channel}>
                              {channel.plantSlotBlocks.map((block, index) => renderBlock(block, channel.channelNumber, index))}
                          </Channel>
                      ))}
                  </Panel>}
          )}
                  <div className='take-photo'>
                      {!photoData && <PictureUpload className='take-photo-btn' changeHandler={receivePhotoData} resetHandler={clearPhoto} />}
                      {photoData && (
                          <div className='camera-output' style={{ background: `url(${photoData}) no-repeat center center/contain` }} onClick={onPhotoClick}>
                          </div>
                      )}
                  </div>
                  <Modal contentClassName='photo-zoom-modal-content' fade={false} fullscreen size="xl" onClick={() => setIsModalOpen(false)} isOpen={isModalOpen} style={{ maxWidth: 'unset', margin: '0' }}>
                      <div className='camera-output' style={{ background: `url(${photoData}) no-repeat center center/contain`, height: `${window.innerHeight}px`, width: `${window.innerWidth}px` }}></div>
     
                  </Modal>
                  {photoData && <StatusBarButton
                      label='Confirm'
                      icon='fas fa-check'
                      type='inline'
                      reversed={true}
                      clickHandler={submitReport}
                      statusSlot={5}                      
                  />
                  }
                  {photoData && <StatusBarButton
                      label='Retake'
                      icon='fas fa-redo'
                      type='inline'
                      clickHandler={clearPhoto}
                      statusSlot={1} />
                  }
              </>
          )}
    </Fragment>
  );
};

export default SubmitPhoto;
