
// Auth Action Types
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const UPDATE_USER_CONTEXT = 'UPDATE_USER_CONTEXT';


// Entity Action Types
export const REQUEST_ERROR = 'REQUEST_ERROR';

//#region 

export const GET_CONTAINERS = 'GET_CONTAINERS';
export const SET_ACCESSIBLE_CONTAINERS = 'SET_ACCESSIBLE_CONTAINERS';
export const SET_CURRENT_CONTAINER = 'SET_CURRENT_CONTAINER';
export const SET_LAST_SELECTED_CONTAINER = 'SET_LAST_SELECTED_CONTAINER';
export const SET_ACCESSIBLE_TENANTS = 'SET_ACCESSIBLE_TENANTS';
export const SET_LAST_SELECTED_TENANT = 'SET_LAST_SELECTED_TENANT';
export const SET_CURRENT_ROLES = 'SET_CURRENT_ROLES';
export const UPDATE_SESSION_INFORMATION = 'UPDATE_SESSION_INFORMATION';
export const UPDATE_USER_INFORMATION = 'UPDATE_USER_INFORMATION';


export const SET_ACCESSIBLE_SEEDTYPES = 'SET_ACCESSIBLE_SEEDTYPES';
export const SET_ACCESSIBLE_TEMPLATES = 'SET_ACCESSIBLE_TEMPLATES';
export const SET_LOADING_SEEDTYPES = 'SET_LOADING_SEEDTYPES'


export const CREATE_CONTAINER = 'CREATE_CONTAINER';
export const UPDATE_CONTAINER = 'UPDATE_CONTAINER';
export const DELETE_CONTAINER = 'DELETE_CONTAINER';

export const SET_IS_RUNNING = 'SET_IS_RUNNING';

//#endregion

//#region Tenant Types

export const GET_TENANTS = 'GET_TENANTS';
export const SET_CURRENT_TENANT = 'SET_CURRENT_TENANT';
export const CREATE_TENANT = 'CREATE_TENANT';
export const UPDATE_TENANT = 'UPDATE_TENANT';
export const DELETE_TENANT = 'DELETE_TENANT';

export const UPDATE_ACCESSIBLE_TENANTS = 'UPDATE_ACCESSIBLE_TENANTS';

//#endregion

//#region Process Action Types
export const GET_AVAILABLE_PROCESSES = 'GET_AVAILABLE_PROCESSES';
export const OCCUPANCY_LOADED = 'OCCUPANCY_LOADED';

export const OCCUPANCY_UPDATED ='OCCUPANCY UPDATED'
export const START_PROCESS = 'START_PROCESS';

export const MODIFY_PROCESS = 'MODIFY_PROCESS';
export const PROCESS_UPDATE = 'PROCESS_UPDATE';

export const ABORT_PROCESS = 'ABORT_PROCESS';
export const RESET = 'RESET';


export const SET_REPORT_DATA = 'SET_REPORT_DATA';

export const SET_SELECTION = 'SET_SELECTION';

export const SET_SEEDTYPE = 'SET_SEEDTYPE';

export const UPDATE_WEIGHT = 'UPDATE_WEIGHT';

export const UPDATE_DISPLAY_PREFIX = 'UPDATE_DISPLAY_PREFIX';
export const UPDATE_DISPLAY_VALUE = 'UPDATE_DISPLAY_VALUE';

//#endregion

//#region ProduceTypes Types

export const ADD_PRODUCE_TYPE = 'GET_PRODUCE_TYPE';
export const DELETE_PRODUCE_TYPE = 'DELETE_PRODUCE_TYPE';
export const GET_MANY_PRODUCE_TYPES = 'GET_MANY_PRODUCE_TYPES';
export const GET_PRODUCE_TYPE = 'GET_PRODUCE_TYPE';
export const UPDATE_PRODUCE_TYPE = 'UPDATE_PRODUCE_TYPE';

//#endregion

//#region HarvestingProcedures Types
export const GET_MANY_HARVESTING_PROCEDURES = 'GET_MANY_HARVESTING_PROCEDURES';

// UI Action Types
export const SET_LOADING = 'SET_LOADING';
export const SET_LOADING_ELEMENT_NAME = 'SET_LOADING_ELEMENT_NAME';

export const SET_PERSISTANT_NOTIFICATION = 'SET_PERSISTANT_NOTIFICATION';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_INFO = 'SET_INFO';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const CLEAR_INFO = 'CLEAR_INFO';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const CLEAR_INFORMATION = 'CLEAR_INFORMATION';

// Selectable Context Types
export const SET_SELECTION_START = 'SET_SELECTION_START';
export const SET_SELECTION_END = 'SET_SELECTION_END';
export const REMOVE_SEQUENCE = 'REMOVE_SEQUENCE';
export const RESET_SELECTION = 'RESET_SELECTION';

// Seed Management Action Types
export const SET_ALL_SEEDTYPES = 'SET_ALL_SEEDTYPES';
export const SET_LABEL_PRINTER_TEMPLATE_NAMES = 'SET_LABEL_PRINTER_TEMPLATE_NAMES';
export const UPDATE_SEEDTYPE = 'UPDATE_SEEDTYPE';
export const DELETE_SEEDVARIANTTYPE = 'DELETE_SEEDVARIANTTYPE';
export const DELETE_SEEDTYPE = 'DELETE_SEEDTYPE';
export const SAVE_NEW_SEEDTYPE = 'SAVE_NEW_SEEDTYPE';

// ServiceDesk Action Types
export const GET_SERVICEDESK_ISSUES = 'GET_SERVICEDESK_ISSUES';

// Session Information Action Types
export const SET_SESSION_INFORMATION = 'SET_SESSION_INFORMATION';