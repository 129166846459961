import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PROCESS_ROUTES } from '../../constants/routes/clientRoutes';
import AuthContext from '../../contexts/auth/AuthContext';
import ProcessContext from '../../contexts/process/ProcessContext';
import HarvestingProceduresContext from '../../contexts/management/harvestingProcedures/HarvestingProceduresContext';
import UIContext from '../../contexts/ui/UIContext';
import Button from './Button';
import Loading from '../ui-elements/Loading';
import { MARKETING } from '../../constants/roles/roles';
import MarketingUserView from './MarketingUserView';

const ProcessSelector = () => {
  const { activeProcessId, processes, startProcess, processTypes, abortProcess, resetProcessState, processFinished } =
    useContext(ProcessContext);

  const { processIcons, loading, setInfo } = useContext(UIContext);
  const { getAvailableProcesses } = useContext(ProcessContext);
  const { currentTenant, userInformation, currentContainer, accessibleContainers, currentRoles, accessibleSeedTypes, accessibleTemplates, seedTypesLoading } =
    useContext(AuthContext);
  const { allHarvestingProcedures, harvestingProceduresLoading } = useContext(HarvestingProceduresContext);
  const seedVariantTypesExist = accessibleSeedTypes?.some(seedType => seedType.seedVariantTypes && seedType.seedVariantTypes.some(variant => variant.count > 0));
  
  const abort = async () => {
    await getAvailableProcesses();
  };

  useEffect(() => {
    abort();
    setInfo('Start a process');
  }, []);

  useEffect(() => {
    if (!processes) {
      return;
    }

  }, [userInformation, currentTenant, accessibleContainers, currentContainer]);

  const navigate = useNavigate();

  const getProcessType = (type) => {
    return Object.entries(processTypes).find(([key, value]) => value === type)[0];
  };

  const handleClick = async (type) => {
    const processType = getProcessType(type);
    await startProcess(type);
    navigate(PROCESS_ROUTES[processType]);
  };  

  const renderComponents = () => {
    return accessibleSeedTypes && accessibleSeedTypes?.filter((seedType) => !seedType.outdated).length > 0 ? (
      <div className='process-selector container'>
        {processes.map((process) => {
          if (process.type == 3 && allHarvestingProcedures.length == 0) {
            return <Button
              key={process.type}
              className='process-button'
              label={process.title}
              clickHandler={() => handleClick(process.type)}
              icon={processIcons[process.type]}
              disabled={true}
            />
          }
          else if (process.type === 0 && !seedVariantTypesExist) {
            return <Button
              key={process.type}
              className='process-button'
              label={process.title}
              clickHandler={() => handleClick(process.type)}
              icon={processIcons[process.type]}
              disabled={true}
            />
          }
          else {
            return <Button
              key={process.type}
              className='process-button'
              label={process.title}
              clickHandler={() => handleClick(process.type)}
              icon={processIcons[process.type]}
            />
          }
        })}
      </div>
    ) : (
      <div className='container'>
        <div style={{ textAlign: 'center' }}>
          <h3>To be able to start working:</h3><br />
        </div>

        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridAutoFlow: 'column', gridGap: '40px' }}>
          {(accessibleContainers?.length < 1 || accessibleTemplates?.length < 1 || !accessibleSeedTypes || accessibleSeedTypes?.length < 1 || accessibleSeedTypes?.filter((seedType) => !seedType.outdated).length < 1) &&
            <Button
              key='containerfirst'
              className='process-button'
              label={accessibleContainers?.length > 0 ? 'Container created' : 'Create Container'}
              clickHandler={() => navigate('/management/containers/create')}
              icon={accessibleContainers?.length > 0 ? 'fas fa-check' : 'fas fa-dumpster'}
              disabled={accessibleContainers?.length > 0}
              style={{ padding: '20px !important' }}
            />
          }
          {(accessibleContainers.length < 1 || accessibleTemplates.length < 1 || !accessibleSeedTypes || accessibleSeedTypes?.length < 1 || accessibleSeedTypes.filter((seedType) => !seedType.outdated).length < 1) &&
            <Button
              key={'templatesfirst'}
              className='process-button'
              label={accessibleTemplates.length > 0 ? 'Templates created' : 'Create label printer templates'}
              clickHandler={() => navigate('/templates')}
              icon={accessibleTemplates.length > 0 ? 'fas fa-check' : 'fas fa-paperclip'}
              disabled={accessibleTemplates.length > 0}
              style={{ padding: '20px !important', minHeight: '200px' }}
            />
          }
          {(accessibleContainers.length < 1 || accessibleTemplates.length < 1 || !accessibleSeedTypes || accessibleSeedTypes?.length < 1 || accessibleSeedTypes.filter((seedType) => !seedType.outdated).length < 1) &&
            <Button
              key={'seedsfirst'}
              className='process-button'
              label={(accessibleSeedTypes?.length > 0) ? 'Seeds created' : 'Create Seeds'}
              clickHandler={() => navigate('/management/seed-types/list')}
              icon={(accessibleSeedTypes?.length < 1) ? 'fas fa-seedling' : 'fas fa-check'}
              disabled={accessibleTemplates?.length < 1 || accessibleContainers?.length < 1 || accessibleSeedTypes?.length > 0}
              style={{ padding: '20px !important' }}
            />
          }          
        </div>
      </div>
    );
  }

  return !(loading || seedTypesLoading || harvestingProceduresLoading) ?
      (currentRoles?.includes(MARKETING) && currentRoles?.length === 1 ?
          <MarketingUserView/> : renderComponents())
      :<Loading fullScreen={true}/>
};

export default ProcessSelector;