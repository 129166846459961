import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SelectableContext from '../../../../contexts/local/selectable/SelectableContext';
import ProcessContext from '../../../../contexts/process/ProcessContext';
import StatusBarButton from '../../../layout/StatusBarButton';
import Channel from './Channel';
import Panel from './Panel';
import PlantBlockSelectable from './PlantBlockSelectable';
import Loading from '../../../ui-elements/Loading';
import UIContext from '../../../../contexts/ui/UIContext';
import SelectionSidebar from '../../../layout/Sidebar/SelectionSidebar';

const PanelSelection = ({ forwardTo, photo }) => {
  const { additionalProcessInformation, loadOccupancy, occupancyArray, activeProcessId, continueProcess } =
    useContext(ProcessContext);

  const { loading } = useContext(UIContext);
  const navigate = useNavigate();
  const usePhoto = photo;
  const [showContinueButton, setShowContinueButton] = useState(false);

  const load = async () => {
    await loadOccupancy(additionalProcessInformation.sourceEntity, 'panel');
  };

  useEffect(() => {
    load();
  }, []);

  const { selection, Sidebar, isSelecting } = useContext(SelectableContext);

  useEffect(() => {
    if (selection.length > 0 && !isSelecting) {
      setShowContinueButton(true);
    } else if (isSelecting) {
      setShowContinueButton(false);
    }
  }, [selection, isSelecting]);

  const proceedToConfirmation = async () => {
    try {
      await continueProcess(activeProcessId, selection);
      navigate(forwardTo);
    } catch (error) {
      console.error(error);
    }    
  };

  return loading ? (
    <Loading fullScreen />
  ) : (
    <Fragment>
      {Sidebar}
      <Panel>
        {occupancyArray.map((channel) => (
          <Channel key={channel.channelNumber} channel={channel}>
            {channel.plantSlotBlocks.map((block, index) => (
              <PlantBlockSelectable
                key={`${channel.channelNumber}-${block.startPlantSlotOrdinalNumber}-${block.endPlantSlotOrdinalNumber}`}
                block={block}
                channelNr={channel.channelNumber}
                index={index}
                disabled={
                  !block.isOccupied ||
                  (additionalProcessInformation.endOfLife && block.isOccupied && block.harvestCount === 0)
                }
                style={{ height: block.endPlantSlotOrdinalNumber - block.startPlantSlotOrdinalNumber + 1 + '%' }}
                endOfLife={additionalProcessInformation?.endOfLife}
                photo={usePhoto}
                harvesting={false}
              />
            ))}
          </Channel>
        ))}
      </Panel>
      {showContinueButton && (
        <Fragment>
          <StatusBarButton
            label='Continue'
            icon='fas fa-chevron-right'
            reversed={true}
            clickHandler={proceedToConfirmation}
            statusSlot={5}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default PanelSelection;