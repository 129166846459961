import axiosClient from "../../http-common";

class LabelPrinterTemplateApiService {
  printLabel(tenantId, containerId, request){
    return axiosClient.post(`/api/v1/Tenants/${tenantId}/Containers/${containerId}/PrintLabel`, request);
  }

  printLabelForProcess(tenantId, containerId, processId){
    return axiosClient.post(`/api/v1/Tenants/${tenantId}/Containers/${containerId}/Processes/${processId}/PrintLabel`);
  }
}

export default new LabelPrinterTemplateApiService();