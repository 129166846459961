import React from 'react';

const Scale = ({ title, weighingData, element, children, layout }) => {
  const weight = weighingData ? weighingData.weight?.toFixed(3) : 0.0.toFixed(3);
  return (
    <div className={layout}>
      <div className='head'>
        <div className='plant'>
          <i className={element && element[0].isMultiharvestable ? 'fab fa-pagelines':'fas fa-seedling'}></i>
          <span className='plant-icon-text'>{title}</span>
        </div>
        <div className='top'></div>
        <div className='bottom'></div>
      </div>
      <div className='body'>
        <div className='screen'>{weight} kg</div>
        {children}
      </div>
    </div>
  );
};

export default Scale;
