import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SelectableContext from '../../../../contexts/local/selectable/SelectableContext';
import ProcessContext from '../../../../contexts/process/ProcessContext';
import UIContext from '../../../../contexts/ui/UIContext';
import StatusBarButton from '../../../layout/StatusBarButton';
import Loading from '../../../ui-elements/Loading';
import Tray from '../../Tray';
import SeedSlotSelectable from './SeedSlotSelectable';
import SelectionSidebar from '../../../layout/Sidebar/SelectionSidebar';

const OccupiedTraySlotSelector = ({ forwardTo }) => {
  const { loadOccupancy, occupancyArray, activeProcessId, continueProcess, additionalProcessInformation } =
    useContext(ProcessContext);

  const { selection, Sidebar, isSelecting } = useContext(SelectableContext);

  const navigate = useNavigate();
  const { loading } = useContext(UIContext);
  
  const [showContinueButton, setShowContinueButton] = useState(false);

  const load = async () => {
    await loadOccupancy(additionalProcessInformation.sourceEntity);
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (selection.length > 0 && !isSelecting) {
      setShowContinueButton(true);
    } else if (isSelecting) {
      setShowContinueButton(false);
    }
  }, [selection, isSelecting]);

  const proceedToSlotSelectionConfirmation = async () => {
    try {
      await continueProcess(activeProcessId, selection);
      navigate(forwardTo);
    } catch (error) {
      console.error(error);
    }
  };

  const reversedArray = [...occupancyArray].reverse();

  return loading ? (
    <Loading fullScreen />
  ) : (
    <Fragment>
      {Sidebar}
      <Tray>
        {reversedArray.map((unit) => (
          <SeedSlotSelectable
            key={unit.index}
            displayName={!unit.isAvailable ? unit.display : ''}
            index={unit.index}
            selectable={!unit.isAvailable}
            processName='discarding'
            isMultiharvestable={unit.isMultiharvestable}
          />
        ))}
      </Tray>
      {showContinueButton && (
        <Fragment>
          <StatusBarButton
            label='Continue'
            icon='fas fa-chevron-right'
            reversed={true}
            type='inline'
            clickHandler={proceedToSlotSelectionConfirmation}
            statusSlot={5}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default OccupiedTraySlotSelector;