// #region Libraries
import { useReducer, useContext, useEffect } from "react";
// #endregion

// #region Contexts
import AuthContext from "../../auth/AuthContext";
import HarvestingProceduresContext from "./HarvestingProceduresContext";
import ProcessContext from "../../process/ProcessContext";
// #endregion

// #region Reducers
import HarvestingProceduresReducer from "./HarvestingProceduresReducer";
// #endregion

// #region Services
import HarvestingProceduresApiService from "../../../services/apiServices/HarvestingProceduresApiService";
// #endregion

// #region Constants
import { GET_MANY_HARVESTING_PROCEDURES, SET_LOADING } from "../../types/contextTypes";
import FarmerAppApiErrorMapper from "../../../utility/FarmerAppApiErrorMapper";
import { HELP_DESK_LINK } from "../../../constants/helpDesk";
// #endregion

/**
 * `HarvestingProceduresProvider` is a React context provider component.
 * It manages the state and logic related to harvesting procedures.
 * 
 * @component
 * @example
 * return <HarvestingProceduresProvider>{children}</HarvestingProceduresProvider>
 */
const HarvestingProceduresProvider = (props) => {
  // #region States
  const initialState = {
    allHarvestingProcedures: [],
    harvestingProceduresLoading: false
  };
  const [state, dispatch] = useReducer(HarvestingProceduresReducer, initialState);
  // #endregion

  // #region Context properties
  const { currentTenant, currentContainer } = useContext(AuthContext);
  const { notifyError } = useContext(ProcessContext);
  // #endregion

  // #region useEffects
  useEffect(() => {
    if (currentTenant && currentContainer) {
      getAllHarvestingProcedures();
    }
  }, [currentTenant, currentContainer]);
  // #endregion

  // #region Functions

  /**
   * Gets all harvesting procedures.
   */
  const getAllHarvestingProcedures = () => {
    setLoading(true);
    HarvestingProceduresApiService.getManyHarvestingProcedures(currentTenant?.tenantId, currentContainer?.id)
      .then(
          (response) => setAllHarvestingProcedures(response.data.data))
      .catch(handleError)
      .finally(
          () => setLoading(false));;
  }

  /**
   * Gets the details of the harvesting procedure with the given id.
   * @param {string} harvestingProcedureId - The id of the harvesting procedure.
   * @returns The details of the harvesting procedure with the given id.
   */
  const getHarvestingProcedure = (harvestingProcedureId) => {
    return HarvestingProceduresApiService.getHarvestingProcedureByHarvestingProcedureId(currentTenant?.tenantId, currentContainer?.id, harvestingProcedureId)
      .then((response) => response.data.data)
      .catch((error) => {
        notifyError(<>We have encountered an issue on our side while getting the harvesting procedures. Please contact the support team {HELP_DESK_LINK}.</>);
        console.error(error);
      });
  }

  /**
   * Sets the given harvesting procedures in the state.
   * @param {Array} harvestingProcedures - The array of harvesting procedures.
   */
  const setAllHarvestingProcedures = (harvestingProcedures) => {
    dispatch({
      type: GET_MANY_HARVESTING_PROCEDURES,
      payload: harvestingProcedures
    });
  }

  /**
   * Sets the loading state property to the given value.
   * @param {boolean} harvestingProceduresLoading - A value indicating whether the context is loading data.
   */
  const setLoading = (harvestingProceduresLoading) => {
    dispatch({
      type: SET_LOADING,
      payload: harvestingProceduresLoading
    });
  }

  /**
   * Handles api errors.
   * @param {object} error 
   */
  const handleError = (error) => {
    if (error.message === 'Not found.') {
      notifyError(<>We have encountered an issue while getting the produce types. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
    } else {
      notifyError(<>We have encountered an issue on our side while getting the produce types. Please contact the support team {HELP_DESK_LINK}.</>);
    }
    console.error(error);
  }
  // #endregion

  return (
    <HarvestingProceduresContext.Provider 
      value={{
        ...state, 
        getAllHarvestingProcedures 
      }}
    >
      {props.children}
    </HarvestingProceduresContext.Provider>
  );
};

export default HarvestingProceduresProvider;