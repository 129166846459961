import React, { Fragment, useContext, useEffect, useState, useRef, useCallback } from 'react';
import heic2any from 'heic2any';
import UIContext from '../../../contexts/ui/UIContext';
import Button from '../../layout/Button';
import ProcessContext from '../../../contexts/process/ProcessContext';


export const PictureUpload = ({changeHandler, resetHandler, withPreview=false, withButton=false}) => {
  const { loading, setLoading, addNotification } = useContext(UIContext);
  const {notifyError} = useContext(ProcessContext);
  const inputRef = useRef(null);
  const [src, setSrc] = useState(null);

  // Reads the image as byte array.
  const readImage = (img) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(img);
    });
  };

  // Reads the image as base 64 string.
  const readImageAsBase64 = (img) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(img);
    });
  };

  // Handles click on the file upload button and "clicks" on the input element.
  const clickFileInput = () => {
    let input = document.getElementById('poca');
    input.click();
  };

  // Checks if the file is of a supported format.
  const isPicture = (file) => {
    let fileName = file;
    const validImageTypes = ['.jpg', '.png', '.jpeg', '.heic'];
    
    for (let i = 0; i < validImageTypes.length; i++) {
      if (fileName?.toLowerCase().endsWith(validImageTypes[i])) {
        return true;
      }
    }
    return false;
  };

  const redirectToReportForm = () => {
    resetHandler();
  }

  // Called if a file is uploaded.
  const onInputChange = async (event) => {
    try {
      let img = event.target.files[0];

      // Firstly, let's make sure the file is a jpg or heic-picture.
      if (!isPicture(img.name)) {
        throw new Error('Please upload JPG, PNG or HEIC images only.');
      }

      // If it's heic, let's remember that for later.
      let isOtherFormat = img.name.toLowerCase().endsWith('.heic');

      // // Now we need to extract the geodata or let the user know if there isn't any.
      let data = await readImage(img);
      // const tags = await ExifReader.load(data);
      // const important = window.navigator;
      // var geoLocationClient = new ExifGeolocation(tags);

      let parentData;

      // Now that we've extracted the geodata, we need convert the heic picture to jpg.
      if (isOtherFormat) {
        let blob = new Blob([img], { type: 'image/heic' });
        setLoading(true);
        await heic2any({ blob, toType: 'image/jpeg', quality: 0.8 }).then((conversionResult) => {
          blob = conversionResult;
        });

        blob.lastModifiedDate = new Date();
        blob.name = img.name;
        data = await readImageAsBase64(blob);
        setLoading(false);
        parentData = {
          photoData: data
        };
      } else {
        data = await readImageAsBase64(img);

        parentData = {
          photoData: data
        };
      }

      setSrc(data);
      changeHandler(parentData);
    } catch (error) {
      notifyError("The image you tried to upload has the wrong type. Please provide a JPG, PNG or HEIC image and try again.");
      changeHandler(null);
    } finally {
      //setLoading(false);
    }
  };

  return (
    <div className='file-select'>
      <Button className='upload-photo-btn' icon='fas fa-camera' label='Take Photo' clickHandler={clickFileInput} />
      <input ref={inputRef} type='file' id='poca' onChange={onInputChange} style={{display: 'none'}} />
      {withPreview && <img id='picture-upload-img' src={src ?? '.\\images\\default.png' } />}          
    </div>
  );
}