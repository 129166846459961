import React from 'react';
import { useLocation } from 'react-router-dom';

const PlantBlockWeighing = ({ block, channelNr, isSelected, isInSelection, index, toBeWeighed, isFinished, icon = 'fas fa-gift', ...rest }) => {
    const blockCode = `${channelNr + 1}/${index + 1}`;
    const slotSpan = block.endPlantSlotOrdinalNumber - block.startPlantSlotOrdinalNumber;
    const location = useLocation();

    /* @returns an Icon Element with the fitting font-awesome classname */
    const getIcon = () => {
      let doneIcon = location?.pathname == "/weighing/confirm-weight" ? 'fas fa-balance-scale-left' : 'fas fa-gift'
      if(isFinished && !block?.isMultiharvestable) return <i className={`${doneIcon} plant-icon`}></i>;
      
      return <i className={block.harvestCount >= 1 ? 'fab fa-pagelines plant-icon': 'fas fa-seedling plant-icon'}></i>;
    }

    return (
      <div
        className='channel-block weighing'
        data-selected={isSelected}
        data-occupied={block.isOccupied}
        data-to-be-weighed={toBeWeighed}
        data-finished={isFinished}
        data-in-selection={isInSelection}
        style={{ width: block.endPlantSlotOrdinalNumber - block.startPlantSlotOrdinalNumber + 1 + "%" }}
        {...rest}
      >
        {getIcon()}
        {block.isOccupied && <span className='plant-name'>{block.seedName}</span>}
        {slotSpan >= 5 && <span className='block-code'>{blockCode}</span>}
      </div>
    );
}

export default PlantBlockWeighing
