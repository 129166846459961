import React, { useContext, useReducer } from 'react';
import { ApplicationPaths } from '../../components/api-authorization/ApiAuthorizationConstants';
import authService from '../../components/api-authorization/AuthorizeService';
// import 'react-toastify/dist/ReactToastify.css';
import {
  GET_AVAILABLE_PROCESSES,
  OCCUPANCY_LOADED,
  OCCUPANCY_UPDATED,
  PROCESS_UPDATE,
  REQUEST_ERROR,
  RESET,
  SET_REPORT_DATA,
  SET_SEEDTYPE,
  SET_SELECTION,
  START_PROCESS,
  UPDATE_DISPLAY_PREFIX,
  UPDATE_DISPLAY_VALUE,
} from '../types/contextTypes';
import { PROCESS_BASE_URL, CONTROL_PROCESS_URL, ENTITIES_BASE_URL } from '../../constants/routes/api';
import { MULTIHARVEST, REPRINT } from '../types/processControlTypes';
import { HttpAgent } from '../../utility/HttpAgent';
import UIContext from '../ui/UIContext';
import ProcessContext from './ProcessContext';
import ProcessReducer from './ProcessReducer';
import AuthContext from '../auth/AuthContext';
import { MARKETING } from '../../constants/roles/roles';
import ProcessesApiService from '../../services/apiServices/ProcessesApiService';

const ProcessState = (props) => {
  const initialState = {
    processes: [],
    backIsAllowed: false,
    activeProcessId: null,
    processError: null,
    selection: null,
    reportData: null,
    additionalProcessInformation: null,
    requestedInformationType: null,
    processFinished: false,
    processStarted: false,
    activeProcessType: null,
    selectedDestinationSlot: null,
    destinationSlotType: 'TraySlot',
    destinationEntityNumRows: 2,
    destinationEntityNumCols: 8,
    occupancyArray: [],
    numPadDisplay: {
      prefix: null,
      value: '',
      combine: function () {
        return this.prefix + this.value;
      },
    },
    informationTypes: {
      EntityName: 0,
      Text: 1,
      Integer: 2,
      Photo: 3,
      Weight: 4,
      SeedSlotSelection: 5,
      PlantSlotSelection: 6,
      Confirm: 7,
      TraySlot: 8,
      PanelSlot: 9,
      Option: 10,
    },
    processTypes: {
      Seeding: 0,
      Transplanting: 1,
      Repositioning: 2,
      Harvesting: 3,
      Weighing: 4,
      EndOfLife: 5,
      Discarding: 6,
      Reporting: 7,
      Photographing: 8,
    },
  };

  const [state, dispatch] = useReducer(ProcessReducer, initialState);
  const { setInfo, clearInfo, setLoading, addNotification, addRedInfoNotification } = useContext(UIContext);
  const { currentContainer, currentUser, currentTenant, currentRoles } = useContext(AuthContext);

  const notifySuccess = (message) => {
    addNotification(message, 'success');
  };

  const notifyWarning = (message) => {
    addNotification(message, 'error');
  };

  const notifyError = (message, errorBoundary = null) => {
    addNotification(message, 'error-stay', errorBoundary);
  }

  const notifyInfo = (message) => {
    addNotification(message, 'info');
  }

  const notifyStayInformation = (message) => {
    addNotification(message, 'info-stay');
  }

  const notifyInfoRed = (message) => {
    addRedInfoNotification(message, 'info-red');
  }

  const updateDisplayPrefix = (prefix) => {
    dispatch({
      type: UPDATE_DISPLAY_PREFIX,
      payload: prefix,
    });
  };

  const updateDisplayValue = (newDisplayValue) => {
    dispatch({
      type: UPDATE_DISPLAY_VALUE,
      payload: newDisplayValue,
    });
  };

  const abortProcess = async () => {
    let url = `api/v1/tenants/${currentTenant?.tenantId}/containers/${currentContainer?.id}/processes/${state.activeProcessId}`;
    try {
      setLoading(true);

      var header = { "headers": { "X-Correlation-Id": state.activeProcessId }};
      let result = await HttpAgent.delete(url, header);

      resetProcessState();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
      notifyInfoRed('Process aborted.');
    }
  };

  const finishProcess = async () => {
    let url = `api/v1/tenants/${currentTenant?.tenantId}/containers/${currentContainer?.id}/processes/${state.activeProcessId}`;
    try {
      setLoading(true);

      let result = await HttpAgent.delete(url);

      resetProcessState();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const modifyProcess = async (action, value) => {
    let url = `api/v1/tenants/${currentTenant?.tenantId}/Containers/${currentContainer?.id}/ProcprocessControl/${state.activeProcessId}`;
    let body = {
      value,
      action,
    };

    try {
      setLoading(true);
      var header = { "headers": { "X-Correlation-Id": state.activeProcessId }};
      let result = await HttpAgent.put(url, JSON.stringify(body), header);

      let additionalInfo = null;
      if (result.data.data?.availableInformation) {
        additionalInfo = result.data.data?.availableInformation;
      }
      if (result?.data?.processFinished) {
        notifySuccess('Process successfully finished');
        state.processFinished = true;
      } else {
        if (
          (state.activeProcessType == 3 && state.requestedInformationType == 13) ||
          (state.activeProcessType == 3 &&
            state.requestedInformationType == 14 &&
            additionalInfo.plantSlotOrdinalNumber == state.additionalProcessInformation.plantSlotOrdinalNumber)
        ) {
          // notifySuccess('Label printed');
        }

        if (
          state.activeProcessType == 3 &&
          state.requestedInformationType == 14 &&
          additionalInfo.plantSlotOrdinalNumber != state.additionalProcessInformation.plantSlotOrdinalNumber
        ) {
          notifySuccess('Plant harvested');
        }
      }

      setInfo(result.data?.requestedInformation?.text);

      dispatch({
        type: PROCESS_UPDATE,
        payload: {
          activeProcessType: result.data?.type,
          requestedInformationType: result.data?.requestedInformation?.type,
          processInfoMessage: result.data?.requestedInformation?.text,
          processFinished: result.data?.processFinished,
          additionalProcessInformation: additionalInfo,
          backIsAllowed: result.data.data?.backIsAllowed
        },
      });
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const continueProcess = async (processId, value) => {
    let url = `api/v1/tenants/${currentTenant?.tenantId}/containers/${currentContainer?.id}/processes/${processId}/processsteps/next`;
    let body = {};
    if (value !== undefined) {
      body = {
        type: state.requestedInformationType,
        value,
      };
    }
    
    // else if (value !== undefined && state.requestedInformationType == 7) {
    else if (value !== undefined) {
      body = {
        type: state.requestedInformationType,
        value,
      };
    }
      
    try {
      setLoading(true);
      var header = { "headers": { "X-Correlation-Id": processId }};
      // const result = await HttpAgent.put(url, JSON.stringify(body), header);
      const result = await ProcessesApiService.continueProcess(currentTenant?.tenantId, currentContainer?.id, processId, body, header);
      if (result.data?.status !== 'success') {
        throw new Error(result.data.message);
      }

      let additionalInfo = null;
      if (result.data.data?.availableInformation) {
        additionalInfo = result.data.data?.availableInformation;
      }
      if (result?.data?.data?.processFinished) {
        notifySuccess('Process successfully finished');
        state.processFinished = true;
      } else {
        if (
          (state.activeProcessType == 3 && state.requestedInformationType == 13) ||
          (state.activeProcessType == 3 &&
            state.requestedInformationType == 14 &&
            additionalInfo.plantSlotOrdinalNumber == state.additionalProcessInformation.plantSlotOrdinalNumber)
        ) {
          // notifySuccess('Label printed');
        }

        if (
          state.activeProcessType == 3 &&
          state.requestedInformationType == 14 &&
          additionalInfo.plantSlotOrdinalNumber != state.additionalProcessInformation.plantSlotOrdinalNumber &&
          state.additionalProcessInformation.plantSlotOrdinalNumber != state.additionalProcessInformation.firstPlantHarvested
        ) {
          notifySuccess('Plant harvested');
        }
      }
      setInfo(result.data.data?.requestedInformation?.text);
      dispatch({
        type: PROCESS_UPDATE,
        payload: {
          activeProcessType: result.data.data?.type,
          requestedInformationType: result.data.data?.requestedInformation?.type,
          processInfoMessage: result.data.data?.requestedInformation?.text,
          processFinished: result.data.data?.processFinished,
          additionalProcessInformation: additionalInfo,
          backIsAllowed: result.data.data?.backIsAllowed
        },
      });
    } catch (error) {
      console.error('ERROR IN CONTINUE PROCESS: ', error);
      notifyError(error.message);
      await handleError(error?.message);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  const revertProcess = async (processId) => {
    const url = `api/v1/tenants/${currentTenant?.tenantId}/containers/${currentContainer?.id}/processes/${processId}/processsteps/next`;
    try {
      setLoading(true);
      var header = { "headers": { "X-Correlation-Id": processId }};
      // const result = await HttpAgent.put(url, JSON.stringify(body), header);
      const result = await ProcessesApiService.revertProcess(currentTenant?.tenantId, currentContainer?.id, processId, header);
      if (result.data?.status !== 'success') {
        throw new Error(result.data.message);
      }

      let additionalInfo = null;
      if (result.data.data?.availableInformation) {
        additionalInfo = result.data.data?.availableInformation;
      }
      if (result?.data?.data?.processFinished) {
        notifySuccess('Process successfully finished');
        state.processFinished = true;
      } else {
        if (
          (state.activeProcessType == 3 && state.requestedInformationType == 13) ||
          (state.activeProcessType == 3 &&
            state.requestedInformationType == 14 &&
            additionalInfo.plantSlotOrdinalNumber == state.additionalProcessInformation.plantSlotOrdinalNumber)
        ) {
          // notifySuccess('Label printed');
        }

        if (
          state.activeProcessType == 3 &&
          state.requestedInformationType == 14 &&
          additionalInfo.plantSlotOrdinalNumber != state.additionalProcessInformation.plantSlotOrdinalNumber&&
          state.additionalProcessInformation.plantSlotOrdinalNumber != state.additionalProcessInformation.firstPlantHarvested
        ) {
          notifySuccess('Plant harvested');
        }
      }

      setInfo(result.data.data?.requestedInformation?.text);

      dispatch({
        type: PROCESS_UPDATE,
        payload: {
          activeProcessType: result.data.data?.type,
          requestedInformationType: result.data.data?.requestedInformation?.type,
          processInfoMessage: result.data.data?.requestedInformation?.text,
          processFinished: result.data.data?.processFinished,
          additionalProcessInformation: additionalInfo,
          backIsAllowed: result.data.data?.backIsAllowed
        },
      });
    } catch (error) {
      console.error('ERROR IN REVERT PROCESS: ', error);
      await handleError(error?.message);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  }

  const controlProcess = async (processId, action, showMessage, successMessage, weightInfo) => {
    try {
      let body;
      setLoading(true);
      if (action === REPRINT || action === MULTIHARVEST) {
        body = JSON.stringify({
          action: action,
          value: weightInfo,
        });
      } else {
        body = JSON.stringify({
          action: action,
        });
      }

      let result = await HttpAgent.put(
        `api/v1/tenants/${currentTenant?.tenantId}/Containers/${currentContainer?.id}/Processes/${processId}/modify`,
        body
      );

      if (showMessage) {
        notifySuccess(successMessage, 'success');
      }

      updateProcessState(result);
    } catch (err) {
      console.error(err);
      notifyWarning(err.message);
    } finally {
      setLoading(false);

    }
  };

  const updateProcessState = (result) => {
    let additionalInfo = null;
    if (result.data?.availableInformation) {
      additionalInfo = result.data?.availableInformation;
    }

    dispatch({
      type: PROCESS_UPDATE,
      payload: {
        activeProcessType: result.data?.type,
        requestedInformationType: result.data?.requestedInformation?.type,
        processInfoMessage: result.data?.requestedInformation?.text,
        processFinished: result.data?.processFinished,
        additionalProcessInformation: additionalInfo,
        backIsAllowed: result.data?.backIsAllowed
      },
    });
  };

  const updateProcessStateViaSignalR = (availableInformation, processFinished, requestedInformation, type, backIsAllowed) => {
    dispatch({
      type: PROCESS_UPDATE,
      payload: {
        activeProcessType: type,
        requestedInformationType: requestedInformation?.type,
        processInfoMessage: requestedInformation?.text,
        processFinished: processFinished,
        additionalProcessInformation: availableInformation,
        backIsAllowed: backIsAllowed
      },
    });
  };

  // Create a new function to process panel occupancy
  // Everything else is the same
  const loadOccupancy = async (sourceEntityName, entity) => {
    setLoading(true);
    updateOccupancy([]);
    let url = `${ENTITIES_BASE_URL}/${currentContainer.id}/${sourceEntityName}/Occupancy`;
    let loadedArray = [];
    try {
      // const res = await fetch(url, {
      //   headers: {
      //     'Authorization' : `Bearer ${token}`
      //   }
      // });
      // if(res.status === 401) {
      //   await authService.signOut({returnUrl: ApplicationPaths.Login});
      //   return;
      // }
      const result = await HttpAgent.get(url);
      entity = sourceEntityName.charAt(0) === 'T' || sourceEntityName.charAt(0) === 't' ? 'tray' : 'panel';
      if (entity !== 'panel') {
        loadedArray = result.data.occupancy.map((item, index) => {
          let unitInRow = {
            index: item.ordinalNumber,
            isAvailable: item.occupancy == 'available',
            isSelected: false,
            display: item.occupancy,
            group: item.groupCounter,
            slotSpan: item.slotSpan,
            isMultiharvestable: item.isMultiharvestable,
          };

          return unitInRow;
        });
      } else {
        loadedArray = [...result.data.occupancy];
      }

      dispatch({
        type: OCCUPANCY_LOADED,
        payload: loadedArray,
      });
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
    return loadedArray;
  };

  const updateOccupancy = (updatedOccupancyArray) => {
    dispatch({
      type: OCCUPANCY_UPDATED,
      payload: updatedOccupancyArray,
    });
  };

  const setSelectedSeedType = (seedName) => {
    dispatch({
      type: SET_SEEDTYPE,
      payload: seedName,
    });
  };

  const getAvailableProcesses = async () => {
    try {

      if (!currentContainer?.id)
      {
        return;
      }

      setLoading(true);

      const result = await HttpAgent.get(
        `api/v1/tenants/${currentTenant?.tenantId}/containers/${currentContainer?.id}/processes`
      );

      dispatch({
        type: GET_AVAILABLE_PROCESSES,
        payload: {
          result,
        },
      });
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const startProcess = async (type) => {
    if (type == null || type < 0) {
      throw Error('A process type must be provided.');
    }
    let url = `api/v1/tenants/${currentTenant.tenantId}/containers/${currentContainer?.id}/Processes?type=${type}`;
   
    try {
      setLoading(true);
           
      const result = await HttpAgent.post(url);
      if (result.status !== 'success') {
        dispatch({
          type: REQUEST_ERROR,
          payload: result,
        });
        console.error(result);
      } else {
        dispatch({
          type: START_PROCESS,
          payload: {
            result,
            type,
          },
        });
        await continueProcess(result.data.id);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };
  const splitUpArray = (array, limit) => {
    let newArray = [];
    let temp = [];
    let counter = 0;
    for (let i = 0; i <= array.length; i++) {
      temp.push(array[i]);

      if (counter === limit - 1) {
        newArray.push(temp);
        // if one channel is full, temp array (which is a column) gets pushed to the return array
        temp = [];
        counter = 0;
      } else {
        counter += 1;
        // if the counter exceeds 500 slots - the panel is full and stop working on it
        if (i === array.length - 1) {
          newArray.push(temp);
          break;
        }
      }
    }

    return newArray;
  };

  const handleError = async (error) => {
    console.error(error);
    dispatch({
      type: REQUEST_ERROR,
      payload: error,
    });
  };

  const resetProcessState = () => {

    dispatch({
      type: RESET,
      payload: { ...initialState, processes: state.processes },
    });
  };

  const setSelection = (selection) => {
    dispatch({
      type: SET_SELECTION,
      payload: selection,
    });
  };

  const setReportData = (reportData) => {
    dispatch({
      type: SET_REPORT_DATA,
      payload: reportData,
    });
  };

  return (
    <ProcessContext.Provider
      value={{
        ...state,
        finishProcess,
        getAvailableProcesses,
        loadOccupancy,
        continueProcess,
        revertProcess,
        controlProcess,
        startProcess,
        modifyProcess,
        abortProcess,
        setReportData,
        setSelection,
        resetProcessState,
        updateOccupancy,
        splitUpArray,
        setSelectedSeedType,
        updateDisplayPrefix,
        updateDisplayValue,
        updateProcessState,
        updateProcessStateViaSignalR,
        notifySuccess,
        notifyWarning,
        notifyInfo,
        notifyStayInformation,
        notifyInfoRed,
        notifyError
      }}
    >
      {props.children}
    </ProcessContext.Provider>
  );
};

export default ProcessState;