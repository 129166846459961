import { useContext, useEffect, useReducer, useState } from "react";
import SessionInformationContext from "./SessionInformationContext";
import SessionInformationApiService from "../../services/apiServices/SessionInformationApiService";
import { Auth } from 'aws-amplify';
import FarmerAppApiErrorMapper from "../../utility/FarmerAppApiErrorMapper";
import UIContext from "../ui/UIContext";
import { HELP_DESK_LINK } from "../../constants/helpDesk";
import _ from 'lodash';
import SessionInformationReducer from "./SessionInformationReducer";
import { SET_SESSION_INFORMATION } from "../types/contextTypes";

/**
 * SessionInformationProvider component provides session information context to its children.
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - The child components that will consume the context.
 * @returns {JSX.Element} The SessionInformationContext provider component.
 */
const SessionInformationProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const { addNotification } = useContext(UIContext);

  // Initialize the reducer with the initial state.
  const [state, dispatch] = useReducer(SessionInformationReducer, { sessionInformation: null });

  const { sessionInformation } = state;

  // Fetch user ID when the component mounts
  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          const username = user.username;

          // We check if the current userId is different from the fetched username to avoid unnecessary state updates.
          if (userId !== username) {
            setUserId(username);
            fetchSessionInformation(username); // Fetch session info as soon as the user ID is set
          }
        }
      } catch (error) {
        if (error !== 'The user is not authenticated') {
          console.error('Failed to fetch user ID:', error);
        };
      }
    };

    fetchUserId();
  }, [userId]); // Dependency array ensures this effect runs whenever userId changes.

  /**
   * Compares two objects for equality by converting them to JSON strings.
   * @param {Object} obj1 - The first object to compare.
   * @param {Object} obj2 - The second object to compare.
   * @returns {boolean} True if the objects are equal, false otherwise.
   */
  const isEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

  /**
   * Sets session information by either adding or updating it.
   * @param {Object} newSessionInformation - The new session information to be set.
   */
  const setSessionInformation = async (newSessionInformation) => {
    if (!userId) return;
    // Merges existing session information with the new session information.
    // We use a deep merge here because session information may contain nested objects or arrays (e.g., readServiceDeskIssues).
    // A deep merge ensures that nested properties are updated without losing the existing structure or data. 
    // For example, updating just the lastSelectedContainerId should not remove the readServiceDeskIssues array.
    let allSession;
    if (sessionInformation) {
      allSession = newSessionInformation;
    }
    else {
      allSession = _.merge({}, sessionInformation, newSessionInformation);
    }

    // If the session information hasn't changed, do nothing.
    if (sessionInformation && isEqual(sessionInformation, allSession)) return;

    const sessionExists = sessionInformation;
    if (sessionExists) {
      await updateSessionInformation(userId, allSession);
    } else {
      await addSessionInformation(userId, allSession);
    }
  };

  /**
   * Adds new session information for the user.
   * @param {string} userId - The ID of the user.
   * @param {Object} newSessionInformation - The new session information to be added.
   */
  const addSessionInformation = async (userId, newSessionInformation) => {
    if (!userId || (sessionInformation && isEqual(sessionInformation, newSessionInformation))) return;
    try {
      await SessionInformationApiService.addSessionInformation(userId, newSessionInformation);

      // Dispatch an action to update the session information in the global state.
      dispatch({ type: SET_SESSION_INFORMATION, payload: newSessionInformation });
    } catch (err) {
      const error = FarmerAppApiErrorMapper.mapFarmerAppError(err);
      console.error('Error adding session information:', error);
      addNotification(<>An error occurred while adding session information. Please try again or contact the support team {HELP_DESK_LINK}.</>, 'error-stay');
    }
  };

  /**
   * Updates existing session information for the user.
   * @param {string} userId - The ID of the user.
   * @param {Object} newSessionInformation - The new session information to update.
   */
  const updateSessionInformation = async (userId, newSessionInformation) => {
    if (!userId || (sessionInformation && isEqual(sessionInformation, newSessionInformation))) return;

    try {
      await SessionInformationApiService.updateSessionInformation(userId, newSessionInformation);

      // Dispatch an action to update the session information in the global state.
      dispatch({ type: SET_SESSION_INFORMATION, payload: newSessionInformation });
    } catch (error) {
      const mappedError = FarmerAppApiErrorMapper.mapFarmerAppError(error);
      console.error('Error updating session information:', mappedError);

      if (mappedError.internalStatusCode === 404) {
        // If session information doesn't exist, add it
        await addSessionInformation(userId, newSessionInformation);
      } else {
        addNotification(<>We encountered an issue while updating session information. Please contact the support team {HELP_DESK_LINK}.</>, 'error-stay');
      }
    }
  };

  /**
   * Fetches the session information for the user.
   * @param {string} userId - The ID of the user.
   */
  const fetchSessionInformation = async (userId) => {
    if (!userId) return;

    try {
      const sessionInformationResult = await SessionInformationApiService.getSessionInformation(userId);
      const newSessionInformation = sessionInformationResult.data?.data;
      // Dispatch an action to set the fetched session information in the global state.
      dispatch({ type: SET_SESSION_INFORMATION, payload: newSessionInformation });
      return newSessionInformation;
    } catch (error) {
      // const error = FarmerAppApiErrorMapper.mapFarmerAppError(err);
      console.error('Failed to fetch session information:', error);
      addNotification(<>We encountered an issue while loading the session information. Please contact support.</>, 'error-stay');
    }
  };

  return (
    <SessionInformationContext.Provider value={{ sessionInformation, setSessionInformation, fetchSessionInformation }}>
      {children}
    </SessionInformationContext.Provider>
  );
};

export default SessionInformationProvider;